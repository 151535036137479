<script>
export default {
  name: 'HorizontalSteps',
  props: {
    steps: {
      type: Array
    },
    value: {
      type: Number
    },
    themeColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mutableValue: 0
    }
  },
  watch: {
    value () {
      this.mutableValue = this.value
    },
    mutableValue () {
      this.$emit('input', this.mutableValue)
      this.$emit('change', this.mutableValue)
    }
  },
  created () {
    this.mutableValue = this.value
  }
}
</script>
<template>
  <div class="horizontal-step--container">
    <div
      v-for="(step, index) in steps"
      :key="step"
      class="horizontal-step--step"
      :class="{'active': mutableValue >= index}"
    >
      <p class="horizontal-step--text">{{`${(index + 1)}. ${ $t(step) }`}}</p>
      <span class="horizontal-step--background"></span>
      <span class="horizontal-step--progress" :style="{'background': themeColor ? themeColor :'#36D75E'}"></span>
    </div>
  </div>
</template>
<style lang="scss">
.horizontal-step--container {
  display: flex;
  justify-content: space-between;
  .horizontal-step--step {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    margin-right: 8px;
    .horizontal-step--text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #A9A9A9;
      margin-bottom: 0;
    }
    .horizontal-step--background {
      position: absolute;
      bottom: 0;
      height: 8px;
      width: 100%;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 100px;
    }
    .horizontal-step--progress {
      position: absolute;
      bottom: 0;
      height: 8px;
      width: 0%;
      border-radius: 100px;
      transition: all ease-in-out 200ms;
    }
    &.active {
      .horizontal-step--text {
        color: #212121;
      }
      .horizontal-step--progress {
        width: 100%;
      }
    }
  }
}
</style>
