<script>
import HorizontalSteps from '@/components/general/HorizontalSteps'

export default {
  name: 'FirstAccess',
  components: {
    HorizontalSteps
  },
  computed: {
    setTheme () {
      return this.getTheme(this.$route.query)
    },
    isInvite () {
      return this.$route.query.isInvite === 'true' || this.$route.query.isInvite === true
    }
  },
  data () {
    return {
      horizontalSteps: [
        'first.access:horizontal.steps.1',
        'first.access:horizontal.steps.2',
        'first.access:horizontal.steps.3'
      ],
      progress: 0
    }
  }
}
</script>
<template>
  <section class="firstAccess-selection--container">
    <div class="firstAccess--header">
        <img :src="setTheme.logo" />
    </div>
    <v-container class="firstAccess--body">
      <h2 v-if="progress !== 0" class="first-selection--title mb-1">{{ $t('first.access:title') }}</h2>
      <p v-if="progress !== 0" class="first-subtitle" :class="{'mb-10': isInvite}">{{ $t('first.access:subtitle') }}</p>
      <horizontal-steps v-if="(progress !== 0) && !isInvite" class="first--stape" :value="progress -1" :steps="horizontalSteps" :themeColor="setTheme.primaryColor"></horizontal-steps>
      <router-view @progressChange="set => progress = set.stepProgress"/>
    </v-container>
  </section>
</template>
<style lang="scss">
.firstAccess-selection--container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  .firstAccess--header {
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 24px;
    }
  }
  .firstAccess--body {
    max-width: 1100px;
    padding-top: 100px;
    .first-selection--title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #242321;
    }
    .first-subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #A0A0A0;
    }
    .first--stape {
      margin-top: 50px;
      text-align: left;
    }
  }
}
</style>
